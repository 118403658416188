<template>
  <!-- DATA - RIGHT SCROLLBAR -->
  <!-- :max-height="$vuetify.breakpoint.smAndDown ? 110 : 630" -->
  <v-card
    elevation="0"
    class="overflow-y-auto"
    color="#1a1b1d"
    style="margin-left: 5px"
    min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`"
    :style="
      $vuetify.breakpoint.smAndDown
        ? `border-left: `
        : `border-left:1px solid gray`
    "
  >
    <v-card-text class="">
      <div>
        <div class="">
          <h2 class="HeadTextStyle" :style="fontSize">Education</h2>
        </div>
        <div class="section myservices">
          <div class="container" style="padding-top: 30px">
            <div class="course box">
              <div class="row headRow mx-0 mb-3">
                <i class="greenicon fas fa-book-open pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  IT Academy Serbia
                </p>
              </div>
              <p class="desc-1">
                <span class="textInsideProject">Field of learning: &nbsp;</span
                >Java &nbsp; programming
                <br />
                <span class="textInsideProject">Time: </span> 1 year<br />
                <span class="textInsideProject">Start: </span> 2018 <br />
                <span class="textInsideProject">End: </span> 2019<br />
                <span class="textInsideProject">Education program: </span> Core
                Java, Advanced Java, MySql, Java GUI, Programming in HTML5 with
                JavaScript and CSS3, Object/Relational Mapping ( ORM ) -
                Hibernate, Java Web Programming, Java web services and XML.<br />
                <span class="textInsideProject">Activities:</span> I actively
                participated in a coding group where we practiced coding daily
                and took on new challenges. We worked on coding exercises and
                projects, constantly improving our skills and problem-solving
                techniques.
              </p>
            </div>
            <!-- ugaseni kursevi -->
            <!-- <div class="course box">
              <div class="row headRow mx-0 mb-3">
                <i class="greenicon fas fas fa-book-open pr-3"></i>
                <p class="headText" style="border-bottom: none">Courses</p>
              </div>
              <p class="desc-1">
                <span class="textInsideProject">Platform: </span> Udemy <br />
                <span class="textInsideProject">Number of courses: </span
                >6<br />
                <span class="textInsideProject">Name of courses: </span>Java
                programming Masterclass for softwer developers by Tim Buchalka,
                HTML and CSS for beginners - Build a Website & launch online,
                Build Web Apps with Vue JS 2 & Firebase, Vue - The Complete
                Guide (w/ Router, Vuex, Composition API), Vue JS Essentials with
                Vuex and Vue Router, Vuetify - awesome Vue UI Library.
              </p>
            </div> -->
            <div class="school box">
              <div class="row headRow mx-0 mb-3">
                <i class="greenicon fas fa-graduation-cap pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Agricultural school
                </p>
              </div>
              <p class="desc-1">
                <span class="textInsideProject">Name of school:</span> "Sonja
                Marinković sa domom učenika" Pozarevac <br />
                <span class="textInsideProject">Grade: </span> High<br />
                <span class="textInsideProject">Field of study: </span>
                Veterinary technician <br />
                <span class="textInsideProject">Time: </span> 4 years<br />
                <span class="textInsideProject">Start: </span> 1997 <br />
                <span class="textInsideProject">End: </span>2001 <br />
                <!-- <i>Activities:</i>
                Head of the IT group from the 1st to the end of the 4th year. We have participated in several competitions with amazing results. -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return { "font-size": "1.35em !important" };
        default:
          return { "font-size": "1.4em" };
      }
    },
  },
};
</script>

<style></style>
